import './index.css';
import reportWebVitals from './reportWebVitals';

import React, { ReactNode } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TOS from './pages/TOS';
import { useUser } from './hooks/useUser';
import { Navigate } from 'react-router-dom';
import { UserProvider } from './hooks/useUser';
import { Helmet } from 'react-helmet';
import { OasisBackendProvider } from './hooks/useOasisBackend';
import OAuthSignIn from './pages/OAuthSignIn';
import EuGrantLearnMore from './pages/EUGrantLearnMore';
import UserSettings from './pages/UserSettings';
import DataSpace from './pages/DataSpace';
import Chat from './components/Chat';
import ChatComponent from './pages/TextStreamingChat';

const RequireLogin: React.FC<{ children?: ReactNode }> = (props) => {
  const userApi = useUser();
  if (userApi.state === "LoggedOut") return <Navigate to={"/login"} />
  return <OasisBackendProvider children={props.children} />;
}

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <UserProvider mountingScreen={<></>}>
      <Helmet>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <title>Thoughtful Oasis: AI Product Development</title>
      </Helmet>
        <BrowserRouter future={{ v7_startTransition: true }} basename={''}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/streaming-chat" element={<ChatComponent />} />
            <Route path="eu-grant-learn-more" element={<RequireLogin><EuGrantLearnMore /></RequireLogin>} />
            <Route path="oauth-signup" element={<OAuthSignIn />} />
            <Route path="settings" element={<RequireLogin><UserSettings /></RequireLogin>} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="tos" element={<TOS />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </BrowserRouter>
    </UserProvider>
  </React.StrictMode>,
)


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
