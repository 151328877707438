import styled from "@emotion/styled";
import { Card } from "./ui/card";
export const AppCard = styled(Card)`

    min-width: 300px;
    min-height: 400px;

    svg {
        width: 15px;
        margin-bottom: 2px;
    }
`;
