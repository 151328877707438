"use client";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { cn } from "../utils/cn";
import Modal from 'react-modal';
import React, { useState, useCallback } from 'react'
import _ from 'lodash';
import { Link } from 'react-router-dom';

// TODO Maybe don't use these? idk notification is pretty good

// TODO Refactor these
import { useSignup } from '../hooks/authentication_helper'
import { useUser } from '../hooks/useUser';
import LabelInputContainer from "./ui/LabelInputContainer";
import Checkbox from "./ui/checkbox";

const StyledErrorMessage: React.FC<{ errors: { [field: string]: [string] } }> = (props) => {
  return <div>
    {Object.entries(props.errors).map(([field, errors]) => {
      return <div key={field} className="text-red-500 bold">{_.startCase(field)}: <br /><ul>{errors.map(text => <li key={text}>{text}</li>)}</ul></div>
    })}
  </div>
}

const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

export const SignupForm = ({successCallback, teaser}: {successCallback?: () => void, teaser?: boolean}) => {
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [agreeToTerms, setAgreeToTerms] = useState<boolean>(false);
  const [confirmPassword, setConfirmPassword] = useState<string>();
  const signup = useSignup();
  const userApi = useUser();



  const signupIfPasswordsMatch = useCallback(() => {
    if (password !== confirmPassword) {
      alert("Passwords do not match")
      return
    }

    if (!email || !firstName || !lastName || !password || !confirmPassword) {
      alert("Please fill out all fields")
      return
    }

    if (!agreeToTerms) {
      alert("You must agree to the terms to use this service")
      return
    }

    signup(email, firstName, lastName, password)
      .then((result) => {
        if (result.success) {
          if (userApi.state === "LoggedOut") userApi.updateUserInfo();
          else console.warn("Attempted signup while logged in")
          if(successCallback) {
            successCallback()
          }
        } else {
          alert("Signup failed")
        }
      })
  }, [email, password, signup])




  return (
    <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white">
      <h2 className="font-bold text-xl text-neutral-800 ">
        Welcome to Thoughtful
      </h2>

      <div className="my-8">
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 mb-4">
          <LabelInputContainer>
            <Label htmlFor="firstname">First name</Label>
            <Input 
              id="firstname" 
              placeholder="Rachel" 
              type="text" 
              value={firstName} 
              onChange={(e) => setFirstName(e.target.value)} 
            />
          </LabelInputContainer>
          <LabelInputContainer>
            <Label htmlFor="lastname">Last name</Label>
            <Input 
              id="lastname" 
              placeholder="Carson" 
              type="text" 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)} 
            />
          </LabelInputContainer>
        </div>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Input
            id="email"
            placeholder="janejacobs@greenwichvillage.com" 
            type="email" 
            value={email} onChange={(e) => setEmail(e.target.value)} 
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            placeholder="••••••••"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-8">
          <Label htmlFor="confirmpassword">Conform Password</Label>
          <Input
            id="confirmpassword"
            placeholder="••••••••"
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </LabelInputContainer>
        <Checkbox
          checked={agreeToTerms}
          onChange={(e) => setAgreeToTerms(e.target.checked)}>
          I agree to both the <Link to="/tos">Terms of Service</Link> and the <Link to="/privacy-policy">Privacy Policy</Link>
        </Checkbox>
        <button
          className="mt-4 bg-gradient-to-br relative group/btn from-black to-neutral-600 block w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]"
          type="submit"
          onClick={signupIfPasswordsMatch}
        >
          Sign up &rarr;
          <BottomGradient />
        </button>
        {
          teaser && <div className="pt-2 pb-3 text-sm"> <div className="py-2">Interested in other AI Applications?</div> <Link to="/learn-more" target="_blank" rel="noopener noreferrer"  className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Learn More</Link></div>
        }


      </div>
    </div>
  );
}

const SignUpButton = () => {
  const [modalIsOpen, setIsOpen] = useState(false);

  // TODO Make modal common component with x to close
  return (<>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => (setIsOpen(false))}
      style={{
        overlay: {
          background: 'transparent',
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          border: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          height: 'auto',
          inset: 'auto',
          background: 'transparent',
          // Mobile media query
          // @ts-ignore
          '@media (max-width: 768px)': {
            padding: '1rem',
          },
          zIndex: 1000
        }
      }}
    >
      <div css={cn('bg-white')}>
        <SignupForm />
      </div>
    </Modal>
    <button onClick={() => { setIsOpen(true) }} className="shadow-[0_0_0_3px_#000000_inset] h-fit px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
      Sign Up
    </button>
  </>
  )
}

export default SignUpButton;