import { useState, useEffect, useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useOasisBackend } from './useOasisBackend';
import axios from 'axios';
import { BACKEND_URL } from './useOasisBackend';
import { set } from 'lodash';
import { Message as BackendMessage } from "../api/OasisBackendApi";
import crypto from 'crypto';


export interface FunctionDefinition {
    name: string;
    arguments: string;
}

interface Message {
    role: string;
    content: string;
    function_call?: FunctionDefinition;
}
interface UseChatReturn {
    messages: Message[];
    animatedText: string;
    isComplete: boolean;
    sendMessage: (message: string) => Promise<void>;
    loading: boolean;
    currentMessageKey: string;
}


function generateSecureRandomKey(length = 16) {
    const array = new Uint8Array(length);
    // Fill the array with cryptographically secure random values
    window.crypto.getRandomValues(array);
    // Convert the random values to a string of alphanumeric characters
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < array.length; i++) {
      result += characters.charAt(array[i] % characters.length);
    }
    return result;
  }
  
  

const useChat = (conversationId: string | null, functionsCaller: (functionDefinition: {name: string, arguments: string}) => void, defaultMessage = "Hello, how can I help you?"): UseChatReturn => {
    const [messages, setMessages] = useState<Message[]>([]);
    const [isComplete, setIsComplete] = useState<boolean>(false);
    const [animatedText, setAnimatedText] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(true);
    const db = useOasisBackend();
    const [, setSearchParams] = useSearchParams();
    const [currentMessageKey, setCurrentMessageKey] = useState<string>(generateSecureRandomKey());

       useEffect(() => {
        // Fetch messages from backend
        // If there is no chat id or it can't be parsed into an integer
        if (!conversationId || isNaN(parseInt(conversationId))) {
            console.log("No chat Id", conversationId)
            setMessages([{role: "assistant", content: defaultMessage}])
            setLoading(false)
            return;
        }        
        db.endpoints.conversation.conversationRetrieve({conversation_id: parseInt(conversationId)}).then((response) => {
            setMessages(response.data.messages.map((message: BackendMessage) => ({id: message.id, ...message.message})));
            setLoading(false)
        });
    }, [conversationId]);

    const updateMessagesWhenCompleted = (messageContent:string) => {
        setIsComplete(true);
        setMessages(prevMessages => [...prevMessages, {role: "assistant", content: messageContent}]);
    }
    const sendMessage = useCallback(async (message: string) => {
        try {
            setIsComplete(false);
            setAnimatedText("");
            const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1]; // Get CSRF token from cookies
            const response = await fetch(`${BACKEND_URL}/conversation/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken || '', // Include CSRF token
                },
                body: JSON.stringify({ conversation_id: conversationId, message }),
                credentials: 'include', // Include credentials (cookies) in the request
            });

            setCurrentMessageKey(generateSecureRandomKey());
            setMessages(prevMessages => [...prevMessages, {role: "user", content: message}]);
    
            const reader = response.body?.getReader();
            const decoder = new TextDecoder();

    
            let done = false;
            let messageContent = "";
            let accumulatedArguments = "";
            let functionCallName = "";
            while (!done) {
                const { value, done: doneReading } = await reader?.read() ?? {};
                done = doneReading ?? true;
                const chunkValue = decoder.decode(value, { stream: true });
    
                // Extract JSON objects from the chunk
                const jsonObjects = extractJsonObjects(chunkValue);
    
                // Process each JSON object
                jsonObjects.forEach((parsedChunk:any) => {
                    if (parsedChunk.conversation_id && !conversationId) {
                        // Set the conversation ID if it's received and not already set
                        setSearchParams({ chatId: parsedChunk.conversation_id });
                    } else if (parsedChunk.message) {
                        // Delay the message to simulate typing
                        messageContent += parsedChunk.message;
                        setAnimatedText(prev => {
                            return prev + parsedChunk.message;
                        });
                    } else if (parsedChunk.function_call) {
                        console.log("Function Call", parsedChunk.function_call);
                        functionCallName = parsedChunk.function_call.name;
                        accumulatedArguments = parsedChunk.function_call.arguments;
                    } else if (parsedChunk.completed) {
                        updateMessagesWhenCompleted(messageContent);
                        if (functionCallName) {
                            const fullFunctionCallDefinition = {
                                name: functionCallName,
                                arguments: accumulatedArguments
                            };
                            functionsCaller(fullFunctionCallDefinition);
                        }
                        done = true; // Exit the loop since the process is completed
                    }
                });
            }
        } catch (error) {
            console.error("Failed to send message", error);
        }
    }, [conversationId]);
    
    function extractJsonObjects(chunkValue:string) {
        const jsonStrings = [];
        let bracketCount = 0;
        let currentJson = "";
    
        for (let i = 0; i < chunkValue.length; i++) {
            const char = chunkValue[i];
    
            if (char === '{') {
                if (bracketCount === 0) {
                    currentJson = char;
                } else {
                    currentJson += char;
                }
                bracketCount++;
            } else if (char === '}') {
                bracketCount--;
                currentJson += char;
    
                if (bracketCount === 0) {
                    jsonStrings.push(currentJson);
                    currentJson = "";
                }
            } else if (bracketCount > 0) {
                currentJson += char;
            }
        }
    
        const jsonObjects:any = [];
    
        jsonStrings.forEach(jsonString => {
            try {
                const parsedChunk = JSON.parse(jsonString);
                jsonObjects.push(parsedChunk);
            } catch (error) {
                console.error('Failed to parse JSON:', error);
            }
        });
    
        return jsonObjects;
    }
    return { messages, animatedText, isComplete, sendMessage, loading, currentMessageKey };
};



export default useChat;
