import { useState } from "react";
import { useOasisBackend } from '../hooks/useOasisBackend'
import { Input } from './ui/input';
import Modal from 'react-modal';

const SendPasswordResetButton = () => {
    const [email, setEmail] = useState("")
    const [isOpen, setIsOpen] = useState(false);
    const db = useOasisBackend();

    const sendEmail = async () => {
        // await db.endpoints.passwordResetEmail.passwordResetEmailCreate({email: email})
        alert("You have been emailed if you have an account")
    }

      
      return(<>
      <div className="text-glow text-xs cursor-pointer" onClick={() => setIsOpen(true)}>Reset Password</div>
      <Modal
        isOpen={isOpen} 
        onRequestClose={() => (setIsOpen(false))}
        style={{overlay: {zIndex: 100}, content: {
            padding: 0, 
            zIndex: 1000, 
            background: "transparent", 
            border: 'none',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}}
      >
        <div className="flex flex-col bg-white justify-top flex bg-secondary w-1/2 h-1/2 self-center  p-6 drop-shadow-2xl text-center">
            <h1 className="text-glow glow-shadow text-xl md:text-4xl pb-0">Reset Password</h1>
            <p className="flex pt-6 flex-col text-text gap-4 md:gap-8 md:text-2xl self-center justify-center w-2/3">
                <Input type="text" placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            </p>
            <div className="flex flex-row self-center gap-4 pt-6">
                <button
                    className="mt-4 bg-gradient-to-br px-4 relative group/btn from-black to-neutral-600 block w-fit-content text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]"
                    type="submit"
                    onClick={() => (setIsOpen(false))}
                >
                    Cancel
                </button>    
                <button
                    className="mt-4 bg-gradient-to-br text-nowrap w-fit px-4 relative group/btn from-black to-neutral-600 block text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]"
                    type="submit"
                    onClick={sendEmail}
                >
                    Send Reset Email
                </button>
            </div>
        </div>
    </Modal></>)
}

export default SendPasswordResetButton;