import { useOasisBackend } from "../hooks/useOasisBackend";
import { Card, CardDescription, CardSubTitle, CardTitle } from "../components/ui/card";
import styled from '@emotion/styled'
import ApplicationNavbar from "../components/ApplicationNavbar";
import { AppCard } from "../components/AppCard";


export const SellingPoints = styled.ul`
    list-style-type: disc;
    display: flex;
    flex-direction: column;
    gap: .5rem;

    a {
        color: #0ea5e9;
    }
`;



const EuGrantLearnMore = () => {
    const db = useOasisBackend();

    // Create subscription and redirect to stripe payment page. Start long polling for subscription status.
    const buyNow = async () => {
        const response = await db.endpoints.stripeEuCallCheckout.stripeEuCallCheckoutCreate()
        // Redirect to stripe
        window.location.href = response.data.stripe_checkout_url;
    }

    const redirectToGPT = async () => {
        window.location.href = "/";
    }

    const contactUs = async () => {
        window.location.href = "https://forms.gle/8WuTQePckNyDYTEE7"
    }


    
    return (
<div className="flex flex-col justify-center items-center h-100 w-100">
        <ApplicationNavbar />

            <div className="flex flex-col w-full mt-16 p-8">
                <h1 className="text-xl font-bold">EU Grant/Tender AI Access</h1>
                <div className="flex flex-col md:flex-row">
                    <AppCard>
                        <div>
                            <CardTitle>Individual</CardTitle>
                            <CardSubTitle>Free</CardSubTitle>
                        </div>
                        <CardDescription>
                            <SellingPoints>
                                <li>Access to EU Grant/Tender AI For Grants/Tenders under 50,000 EUR</li>
                            </SellingPoints>
                        </CardDescription>
                        <button  className="mt-4 shadow-[0_0_0_3px_#000000_inset] h-fit-content px-6 py-2 bg-transparent border border-black  text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => redirectToGPT()}>Continue With Free</button>
                    </AppCard>
                    <AppCard className="flex flex-col gap-4">
                        <div>
                            <CardTitle>Small Organization</CardTitle>
                            <CardSubTitle>€100</CardSubTitle>
                        </div>
                        <CardDescription>
                            <SellingPoints>
                                <li>500 Calls to EU Grant/Tender AI For Grants/Tenders for all Fund Sizes</li>
                                <li>Priority Support</li>
                                <li>AI Grant Drafting</li>
                            </SellingPoints>
                        </CardDescription>
                        <button className="mt-4 shadow-[0_0_0_3px_#000000_inset] h-fit-content px-6 py-2 bg-transparent border border-black  text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => buyNow()}>Purchase</button>
                    </AppCard>
                    <AppCard>
                        <div>
                            <CardTitle>Large Organization</CardTitle>
                            <CardSubTitle>Contact Us For Quote</CardSubTitle>
                        </div>
                        <CardDescription>
                            <SellingPoints>
                                <li>Unlimited Calls to EU Grant/Tender AI For Grants/Tenders for all Fund Sizes</li>
                                <li>Priority Support</li>
                                <li>AI Grant Drafting</li>
                                <li>Integration with your data to enable more effective Grant/Proposal Drafting</li>
                            </SellingPoints>
                        </CardDescription>
                        <button  className="mt-4 shadow-[0_0_0_3px_#000000_inset] h-fit-content px-6 py-2 bg-transparent border border-black  text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" onClick={() => contactUs()}>Contact Us</button>
                    </AppCard>
                </div>
            </div>
        </div>
    );
};

export default EuGrantLearnMore;