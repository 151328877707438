import React from "react";
import styled from "@emotion/styled";
import { IconArrowRight } from "@tabler/icons-react";

const DataCardContainer = styled.div`
    color: white;
    background: linear-gradient(290deg, #482852 0%, #04132C 80%);
    box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.2);
`;

type KeywordFundingSourceProps = {
    min_amount: number;
    max_amount: number;
};

type Dimensions = {
    // Any keyname is allowed and the value is a number between 0 and 1
    [key: string]: number;
};


type FundingSourceProps = {
    keywordFields: KeywordFundingSourceProps;
};

const FilledBar = ({dimensionValue}: {dimensionValue:number}) => {
    let fitColor = "#00FF00";
    const height = 80;

    if(dimensionValue > .8){
        fitColor = "#C1FF72"
    }else if(dimensionValue > .6){
        fitColor = "#8EA14F"
    }else if(dimensionValue > .3){
        fitColor = "#737373"
    }else{
        fitColor = "#B35959"
    }


    return(
        <div className={`flex h-[80px] w-[15px] bg-[#545454] rounded-xl`}>
            <div className="w-[15px] self-end rounded-xl" style={{backgroundColor: fitColor, height: `${height*dimensionValue}px`}}>
            </div>
        </div>
    )

}

const Person: React.FC<any> = ({ data }) => {
        return (
        <div className="flex flex-col gap-2">
            <p>Skills: {data.skills}</p>
            <p>Country: {data.member_location_country}</p>
        </div>
    );
}


const FundingSource = ({ data }: {data:any}) => {
    return (
        <div className="flex flex-col gap-2">
            <div>Max Funding: {data.max_amount}</div>
            <div>Deadline: {data.earliest_deadline}</div>
            {data.geographies.length > 1 &&  <div>Locations: {data.geographies?.join(', ')}</div>}
        </div>
    );
};

const Joke = ({ data }: {data:any}) => {
    return (
        <div className="flex flex-col gap-2">
            <div>{data.text}</div>
        </div>
    );
}

const TypeToComponent: { [key: string]: React.FC<any> } = {
    "Person": Person,
    "EUFundingSource": FundingSource,
    "Joke": Joke
};

const TypeTransformation: { [key: string]: (e:any) => {description: string, title:string} } = {
    "Person": (data: any) => {
        return {
            title: `${data.member_name_first} ${data.member_name_last}`,
            description: data.member_job_description,
        };
    },
    "EUFundingSource": (data: any) => {
        return {
            title: data.title || data.topic_title || data.call_title,
            description: data.content,
        }
    },
    "Joke": (data: any) => {
        return {
            title: "",
            description: "",
        }
    }
}

const DimensionContainer = styled.div`
`


const Dimensions: React.FC<{ dimensions: Dimensions }> = ({ dimensions }) => {
    return (
        <DimensionContainer className="flex h-[150px] min-h-[150px] flex-row gap-2 overflow-x-scroll">
            {Object.entries(dimensions).map(([key, value]) => (
                <div className="flex h-full flex-col gap-2 justify-center min-w-18 items-center">
                    <FilledBar dimensionValue={value} />
                    <p className="text-[10px] max-w-14 h-8 text-center overflow-hidden text-ellipsis">{key.replaceAll("_", " ")}</p>
                </div>
            ))}
        </DimensionContainer>
    );
}

const DataCard = ({data, dimensions, database, yAxis, xAxis, nextCard}: {yAxis: string, xAxis: string, data:any, database: string, dimensions: string[], nextCard?: () => void}) => {
    const Component = TypeToComponent[database];
    const normalizedDatum = TypeTransformation[database](data);
    const dimensionsValue = dimensions.reduce((acc, key) => {
        (acc as any)[key] = data[key];
        return acc;
    } , {} as Dimensions)
    return (
        <DataCardContainer className="h-full w-full flex flex-col rounded-xl overflow-hidden">
            <div className="w-full flex-row flex">
                <p className="text-sm">{xAxis}: {data[xAxis]}, {yAxis}: {data[yAxis]}</p>
                {nextCard && <div onClick = {nextCard}><IconArrowRight /></div>}
            </div>
            <div className="px-6 p-2">
                <p className="text-lg">{normalizedDatum.title}</p>
                <p className="overflow-y-scroll text-ellipsis min-h-14 h-10 w-full">{normalizedDatum.description}</p>
                <Component data={data} />
                <Dimensions dimensions = {dimensionsValue} />
            </div>
        </DataCardContainer>
    );
};

export default DataCard;
