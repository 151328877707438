"use client";
import { Label } from "./ui/label";
import { Input } from "./ui/input";
import { cn } from "../utils/cn";
import Modal from 'react-modal';
import React, { useState, useCallback, useEffect } from 'react'
import SendPasswordResetButton from "./SendPasswordResetButton";

import { useLogin } from '../hooks/authentication_helper'
import { useUser } from '../hooks/useUser';
import { Link } from "react-router-dom";


const BottomGradient = () => {
  return (
    <>
      <span className="group-hover/btn:opacity-100 block transition duration-500 opacity-0 absolute h-px w-full -bottom-px inset-x-0 bg-gradient-to-r from-transparent via-cyan-500 to-transparent" />
      <span className="group-hover/btn:opacity-100 blur-sm block transition duration-500 opacity-0 absolute h-px w-1/2 mx-auto -bottom-px inset-x-10 bg-gradient-to-r from-transparent via-indigo-500 to-transparent" />
    </>
  );
};

const LabelInputContainer = ({
  children,
  className,
}: {
  children: React.ReactNode;
  className?: string;
}) => {
  return (
    <div className={cn("flex flex-col space-y-2 w-full", className)}>
      {children}
    </div>
  );
};

export function LoginForm({ successCallback, teaser }: { successCallback: () => void, teaser?: boolean }) {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const userApi = useUser();
  const login = useLogin();

  const submitLogin = useCallback(() => {
    login(email, password).then((result) => {
      if (!result.success) {
        let errorMessage = "A unknown error occurred"
        if (result.error.response.status === 401) {
          errorMessage = "Invalid email or password"
        } else if (result.error?.message) {
          errorMessage = result.error.message
        }

        alert(errorMessage)
        return;
      }
      if (userApi.state === "LoggedOut")
        userApi.updateUserInfo();
      else
        console.warn("Attempted login while logged in");

      successCallback()

    });
  }, [email, password, login]);

  return (
    <div className="max-w-md w-full mx-auto rounded-none md:rounded-2xl p-4 md:p-8 shadow-input bg-white">
      <h2 className="font-bold text-xl text-neutral-800 ">
        Welcome to Thoughtful
      </h2>

      <div className="my-8">
        <LabelInputContainer className="mb-4">
          <Label htmlFor="email">Email Address</Label>
          <Input
            id="email"
            placeholder="janejacobs@greenwichvillage.com"
            type="email"
            value={email} onChange={(e) => setEmail(e.target.value)}
          />
        </LabelInputContainer>
        <LabelInputContainer className="mb-4">
          <Label htmlFor="password">Password</Label>
          <Input
            id="password"
            placeholder="••••••••"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </LabelInputContainer>
        <SendPasswordResetButton />
        <button
          className="mt-4 bg-gradient-to-br relative group/btn from-black to-neutral-600 block  w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset]"
          type="submit"
          onClick={submitLogin}
        >
          Login &rarr;
          <BottomGradient />
        </button>
        {
          teaser && <div className="pt-2 pb-3 text-sm"> <div className="py-2">Interested in other AI Applications?</div> <Link to="/learn-more" target="_blank" rel="noopener noreferrer"  className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Learn More</Link></div>
        }
      </div>
    </div>
  );
}

const LoginModal = ({ modalIsOpen, setIsOpen }: { modalIsOpen: boolean, setIsOpen: (v: boolean) => void }) => {

  // TODO Make modal common component with x to close
  return (<>
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => (setIsOpen(false))}
      style={{
        overlay: {
          background: 'transparent',
          zIndex: 100,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        content: {
          border: 0,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          width: 'fit-content',
          height: 'auto',
          inset: 'auto',
          background: 'transparent',
          // Mobile media query
          // @ts-ignore
          '@media (max-width: 768px)': {
            padding: '1rem',
          },
          zIndex: 1000
        }
      }}
    >
      <div css={cn('bg-white')}>
        <LoginForm successCallback={() => setIsOpen(false)} />
      </div>
    </Modal>
  </>
  )
}

export const RequreLoginModal: React.FC = () => {
  const user = useUser();
  const [modalIsOpen, setIsOpen] = useState(user.state === "LoggedOut");

  useEffect(() => {
    setIsOpen(user.state === "LoggedOut")
  }, [user.state]);

  return <LoginModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />

}

export const LoginButton = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <LoginModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <button onClick={() => { setIsOpen(true) }} className="shadow-[0_0_0_3px_#000000_inset] px-6 py-2 bg-transparent border border-black text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">
        Login
      </button>
    </>
  )
}


export const LoginItem = () => {
  const [modalIsOpen, setIsOpen] = useState(false);
  return (
    <>
      <LoginModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
      <div className="cursor-pointer" onClick={() => { setIsOpen(true) }}>
        Login
      </div>
    </>
  )
}