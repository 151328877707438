import { Navigate } from 'react-router-dom'
import ApplicationNavbar from '../components/ApplicationNavbar'
import { useOasisBackend } from '../hooks/useOasisBackend';
import { useUser } from '../hooks/useUser';

const Settings = () => {
  const userApi = useUser();
  const db = useOasisBackend()
  if (userApi.state === "LoggedOut") return <Navigate to={"/login"} />

  const openStripePortal = async () => {
    const response = await db.endpoints.stripePortal.stripePortalCreate()
    window.location.href = response.data.stripe_portal_url
  }
  const user = userApi.user;
  return (
    <div className="flex flex-col justify-right w-full items-end p-8">
      <ApplicationNavbar />
      <>
        <div className="text-lg w-full sm:w-1/2 pt-[60px] text-left sm:items-start flex flex-col gap-4">
          <h1 className="text-2xl text-glow">User Information</h1>
          <p>
            First Name: {user.first_name}
          </p>
          <p>
            Last Name: {user.last_name}
          </p>
          <p>
            Email: {user.email}
          </p>
          <div className="pt-4 w-full">
            <button onClick={openStripePortal} className="px-8 py-2  bg-black text-white text-sm rounded-md font-semibold hover:bg-black/[0.8] hover:shadow-lg">
              View Stripe Transactions
              </button>
          </div>
        </div>
      </>
    </div>)
}

export default Settings