import { useState, useEffect, Dispatch, SetStateAction } from 'react';

/**
 * A hook that allows you to use local storage with React state. Cannot share state between uses of this hook.
 * @param key The unique key to use to access local storage
 * @param initialValue T must be a type that can be serialized to JSON
 * @param onMount A function that will be called to interpret the initial value from local storage
 */
function useLocalStorage<T>(
  key: string,
  initialValue: T,
  options?: { onMount?: (initialLocalStorageState: T) => T }
): [T, Dispatch<SetStateAction<T>>] {
  const [state, setState] = useState<T>(() => {
    const storedValue = localStorage.getItem(key);
    if (!storedValue) return initialValue;
    if (options?.onMount) return options.onMount(JSON.parse(storedValue));
    return JSON.parse(storedValue);
  });

  useEffect(() => {
    //#FIXME:? Prevent collisions with external localstorage
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}

export default useLocalStorage;
