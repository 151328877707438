import React, { FC, ChangeEvent } from 'react';

interface CheckboxProps {
  checked: boolean;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  children: React.ReactNode;
}

const Checkbox: FC<CheckboxProps> = ({ checked, onChange, children }) => {
  return (
    <label className="flex items-center">
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        className="mr-2"
      />
      <span>{children}</span>
    </label>
  );
};

export default Checkbox;
