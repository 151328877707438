import BackButton from "../components/ui/back_button";

const TOS = () => {
    return (

        <div className="p-5 py-12 mt-8 md:mt-18 flex flex-col gap-3 overflow-scroll">
            <BackButton />
            <h1 className="text-3xl">Terms of Service</h1>
            <h2 className="text-xl">1. Acceptance of Terms</h2>
            By accessing and using the Thoughtful Labs service, users agree to be bound by these terms and conditions. If you do not agree with any part of these terms, you must not use this service.

            <h2 className="text-xl">2. Description of Service</h2>
            Thoughtful provides an AI-powered services for entertainment and productivity purposes. The service is subject to scheduled and unscheduled service interruptions and reliability issues.

            <h2 className="text-xl">3. User Registration and Data</h2>
            To access certain features of the service, users may need to register and provide personal information. Thoughtful Labs is committed to protecting user data in accordance with our Privacy Policy.

            <h2 className="text-xl">4. User Conduct</h2>
            Users agree to use the service lawfully and avoid transmitting harmful or offensive content. Users are responsible for all activities conducted under their account.

            <h2 className="text-xl">5. Subscription and Payment</h2>
            Access to the service may require a subscription. Payments are managed via Stripe, and users agree to comply with Stripe’s terms of service.

            <h2 className="text-xl">6. Intellectual Property</h2>

            You maintain ownership of any data, information, or content you provide to the Thoughtful Labs service, as well as the AI-generated content produced in response to your data.
            Users agree not to provide content that infringes upon the intellectual property rights of others. Users are responsible for ensuring that their use of the service and its content complies with all applicable laws and regulations.

            <h2 className="text-xl">7. Disclaimers</h2>
            Thoughtful Labs provides the service on an "as is" and "as available" basis. We do not guarantee the accuracy or reliability of the AI-generated content.

            <h2 className="text-xl">8. Limitation of Liability</h2>
            Thoughtful Labs shall not be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or related to your use of the service.

            <h2 className="text-xl">9. Indemnification</h2>
            Users agree to indemnify and hold harmless Thoughtful Labs from any claims, damages, or expenses arising from their use of the service.

            <h2 className="text-xl">10. Modification of Terms</h2>
            Thoughtful Labs reserves the right to modify these terms at any time. Continued use of the service after changes constitutes acceptance of the new terms.

            <h2 className="text-xl">11. Contact Information</h2>
            For any questions regarding these terms, please contact us at [Your Contact Information].

            <h2 className="text-xl">12. Termination</h2>
            Thoughtful Labs may terminate or suspend access to the service at any time, without notice, for any reason.
        </div>
    )
}

export default TOS;