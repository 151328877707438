import { useNavigate} from "react-router-dom";
import {
    IconArrowLeft,
  } from "@tabler/icons-react";


const BackButton = () => {
    let navigate = useNavigate();

    return (
        <div className="flex items-center space-x-2 cursor-pointer pb-4" onClick={() => navigate(-1)} >
            <IconArrowLeft />
            <span>Back</span>
        </div>
    )
}

export default BackButton;