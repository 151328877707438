import React, { createContext, useMemo } from "react";
import { OasisBackendApi } from "../api/OasisBackendApi";
import axios from 'axios';
export const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "https://api." + window.location.hostname.replace(/^www\./, '');

axios.defaults.withCredentials = true
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withXSRFToken = true;

type OasisBackendInterface = {
  endpoints: OasisBackendApi<unknown>
}


const OasisBackendContext = createContext<OasisBackendInterface>({ endpoints: new OasisBackendApi<unknown>({ baseURL: BACKEND_URL, withCredentials: true, xsrfCookieName: "csrftoken", xsrfHeaderName: "X-CSRFTOKEN" }) });

const useOasisBackend = () => {
  const result = React.useContext(OasisBackendContext);
  if (result === null) throw new Error("OasisBackendContext is null. either not set up yet or needs to be used in backend provider");
  return result;
};

// TODO: Evaluate: Might not be nessisary if we never change any params
const OasisBackendProvider: React.FC<{ children: React.ReactNode }> = (props) => {
  const endpoints = useMemo(() => {
    const result = new OasisBackendApi<unknown>({ baseURL: BACKEND_URL, withCredentials: true, xsrfCookieName: "csrftoken", xsrfHeaderName: "X-CSRFTOKEN" });
    // setUpAxiosAuthentication(endpoints.instance);
    return result;
  }, []);


  return <OasisBackendContext.Provider value={{ endpoints: endpoints }}>
    {props.children}
  </OasisBackendContext.Provider>
}


export { useOasisBackend, OasisBackendProvider }