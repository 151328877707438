import  SignupButton  from "../components/SignupButton";
import { useUser } from "../hooks/useUser";
import { Helmet } from "react-helmet";
import { AuroraBackground } from "../components/ui/aurora-background";
import ApplicationNavbar from "../components/ApplicationNavbar";
import { Link } from "react-router-dom";
import { OutlinedButton } from "../components/ui/outlined-button";
import { AppCard } from "../components/AppCard";
import { Card, CardDescription, CardSubTitle, CardTitle } from "../components/ui/card";
import { SellingPoints } from "./EUGrantLearnMore";
import React from 'react';

const ContactForm = () => {
    return (
        <>
            {/* @ts-ignore */}
            <iframe 
                style={{
                    width: "100%",
                    height: "100vh",
                    minHeight: "600px", // Ensures a minimum height for the form on smaller screens
                    border: "none",     // Removes the border
                    margin: "0",        // Removes any margin around the iframe
                    padding: "0",       // Removes any padding around the iframe
                    boxSizing: "border-box", // Ensures padding and border are included in the element's total width and height
                }}
                src="https://docs.google.com/forms/d/e/1FAIpQLSeKQouNFEV70fggo9ftzcOIp_lIe3S6mChJvPG1-W8l5dr-HA/viewform?embedded=true" 
            >
                Loading…
            </iframe>
        </>
    );
};

const Home = () => {
    const user = useUser();
    const loggedIn = user.state === "LoggedIn";

    return (
        <div className="flex flex-col">
        <AuroraBackground className="h-fit min-h-[100vh]">
            <ApplicationNavbar />
            <Helmet>
                <meta property="title" content="Thoughtful Oasis - Making data navigable" />
                <meta name="description" content="LLM First Self learning data organization systems" />
                <meta name="keywords" content="HR AI, EU Funding/Granting AI" />
            </Helmet>
            <div className="w-[100vw] min-h-[100vh] flex flex-col md:flex-row md:justify-between p-8 md:pl-24 md:pr-24 md:py-24 py-16 items-start">
                <div className="flex-col text-left justify-left w-fit pt-16">
                    <h1 className="text-5xl w-fit">Thoughtful Oasis</h1>
                    <h5 className="text-3xl my-4 w-fit">Your partner in navigating complex Dataspaces.</h5>
                    <p className="text-xl my-4 w-fit"> 
                        Whether you people, documents, or resources - Our intelligent, fully integrated technology helps you find what you need, when you need it. No keyword guessing, no traversing of unmaintainable folder structures, and no hallucinating generative AI. Transparent, contextual Data-navigation, tailord to your domain.
                    </p>
                    {loggedIn ? <>
                          {/* <Link to={"/eu-grant-learn-more"}>
                            <OutlinedButton onClick={() => {}}>
                                <>Get Full EU/Grant AI Access</>
                            </OutlinedButton>
                            </Link> */}
                        </>
                        : <>              
                        <SignupButton />
                    </>}
                </div>
                <div className="w-full md:w-fit mt-24 p-8 md:p-16">
                    <h3 className="text-3xl font-bold">Our Technology in action</h3>
                    <div className="flex flex-col md:flex-row pt-8 gap-6">
                        <Card className="w-full md:w-1/2">
                            <div>
                                <CardTitle>Navigate the complext landsape of EU grants and tenders</CardTitle>
                                {/* <CardSubTitle></CardSubTitle> */}
                            </div>
                            <CardDescription>
                                Try our custom GPT powered by Oasis Search
                            </CardDescription>
                            <button  className="mt-4 shadow-[0_0_0_3px_#000000_inset] h-fit-content px-6 py-2 bg-transparent border border-black  text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" ><a href="https://chatgpt.com/g/g-BizJYTq3P-eu-financing-search-grant-tender" target="_blank">Try it out</a></button>
                        </Card>
                        <Card className="w-full md:w-1/2">
                            <div>
                                <CardTitle>Find the soundtrack to your life</CardTitle>
                                {/* <CardSubTitle>Free</CardSubTitle> */}
                            </div>
                            <CardDescription>
                                A mood matching music AI powered by Oasis-search
                            </CardDescription>
                            <button  className="mt-4 shadow-[0_0_0_3px_#000000_inset] h-fit-content px-6 py-2 bg-transparent border border-black  text-black rounded-lg font-bold transform hover:-translate-y-1 transition duration-400" ><a href="https://app.demvybes.com" target="_blank">Try it out</a></button>
                        </Card>
                    </div>
                </div>
            </div>

         
            <div className="w-[100vw] min-h-[100vh] h-fit px-16 md:mt-[-86px] z-10  gap-16 flex flex-col">
                <h1 className="text-5xl w-fit text-center">Our Team</h1>
                <div className="flex flex-col md:flex-row gap-16">
                    <div className="flex flex-col gap-2">
                        <img className="rounded max-w-[316px]" src={process.env.PUBLIC_URL + "/kaspar.jpg"} alt="Kaspar Rothenfusser" />
                        <h3 className="text-2xl">Kaspar Rothenfusser</h3>
                        <h5>Chief Innovation Officer & Interim CEO</h5>
                    </div>
                    <div className="flex flex-col gap-2">
                        <img className="rounded max-w-[316px]" src={process.env.PUBLIC_URL + "/bekk.jpeg"} alt="Bekk Blando" />
                        <h3 className="text-2xl">Bekk Blando</h3>
                        <h5>Chief Technology Officer</h5>
                    </div>
                </div>
                <div className="flex flex-col">
                    <h1 className="text-5xl w-fit text-center mt-8">Contact Us</h1>
                    <ContactForm />
                </div>
            </div>
            </AuroraBackground>

        </div>
    );
    }

export default Home;