import Modal from 'react-modal';
import { IconX } from "@tabler/icons-react";
import { useEffect, useReducer, useState } from "react";
import { useOasisBackend } from "../hooks/useOasisBackend";
import { DataSetConfigurationResponse } from '../api/OasisBackendApi';

interface DatasetModalProps {
    isOpen: boolean;
    setIsOpen: (v: boolean) => void;
    setDataSpaceArguments: (v: any) => void;
    dataSpaceArguments: any;
}

type DimensionValues = {
  min: string;
  max: string;
  elas_low: string;
  elas_high: string;
};

type DimensionsState = {
  [dimensionName: string]: DimensionValues;
};

type Action =
  | {
      type: 'SET_DIMENSION_VALUE';
      payload: { dimension: string; field: keyof DimensionValues; value: string };
    }
  | {
      type: 'SET_ALL_DIMENSION_VALUES';
      payload: { field: keyof DimensionValues; value: string };
    }
  | {
      type: 'RESET_DIMENSIONS';
      payload: DimensionsState;
    };

function reducer(state: DimensionsState, action: Action): DimensionsState {
  switch (action.type) {
    case 'SET_DIMENSION_VALUE':
      const { dimension, field, value } = action.payload;
      return {
        ...state,
        [dimension]: {
          ...state[dimension],
          [field]: value,
        },
      };
    case 'SET_ALL_DIMENSION_VALUES':
      const { field: allField, value: allValue } = action.payload;
      const updatedState = { ...state };
      for (const dimension in updatedState) {
        updatedState[dimension] = {
          ...updatedState[dimension],
          [allField]: allValue,
        };
      }
      return updatedState;
    case 'RESET_DIMENSIONS':
      return action.payload;
    default:
      return state;
  }
}

const DatasetModal = ({ isOpen, setIsOpen, setDataSpaceArguments, dataSpaceArguments }: DatasetModalProps) => {
  const [config, setConfig] = useState<DataSetConfigurationResponse | null>(null);
  const db = useOasisBackend();

  const [dataset, setDataset] = useState<string>("");
  const [rankingAlgorithm, setRankingAlgorithm] = useState<string>("");
  const [query, setQuery] = useState<string>("");
  const [operation, setOperation] = useState<string>("");

  const [dimensionsState, dispatchDimensions] = useReducer(reducer, {} as DimensionsState);
  const [globalValues, setGlobalValues] = useState<DimensionValues>({
    min: '',
    max: '',
    elas_low: '',
    elas_high: '',
  });

  useEffect(() => {
    db.endpoints.dataSetConfiguration.dataSetConfigurationRetrieve().then((response) => {
      console.log("Data Config response", response.data);
      setConfig(response.data);
      const initialDataset = Object.keys(response.data.DATA_SETS)[0];
      setDataset(initialDataset);
      setRankingAlgorithm(response.data.DATA_SETS[initialDataset].ranking_methods[0]);
      setOperation(Object.keys(response.data.OPERATIONS)[0]);
      if(!dataSpaceArguments.xAxis && !dataSpaceArguments.yAxis){
        setDataSpaceArguments((dataspaceArguments:any) => ({
            ...dataspaceArguments,
            xAxis: response.data.DATA_SETS[initialDataset].dimensions[0],
            yAxis: response.data.DATA_SETS[initialDataset].dimensions[1],
            idealXAxisCenter: 0.5,
            idealYAxisCenter: 0.5,
            }));
      }
    });
  }, []);

  useEffect(() => {
    if (config && dataset) {
      const dimensions = config.DATA_SETS[dataset].dimensions as string[];
      const initialState: DimensionsState = dimensions.reduce(
        (acc: DimensionsState, dimension: string) => {
          acc[dimension] = {
            min: '0',
            max: '0',
            elas_low: '0',
            elas_high: '0',
          };
          return acc;
        },
        {} as DimensionsState
      );
      dispatchDimensions({ type: 'RESET_DIMENSIONS', payload: initialState });
      setRankingAlgorithm(config.DATA_SETS[dataset].ranking_methods[0]);
    }
  }, [config, dataset]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onRequestClose={() => setIsOpen(false)}
        style={{
          overlay: {
            background: 'transparent',
            zIndex: 100,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
          content: {
            border: 0,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 'fit-content',
            height: 'auto',
            inset: 'auto',
            background: 'transparent',
            // Mobile media query
            // @ts-ignore
            '@media (max-width: 768px)': {
              padding: '1rem',
            },
            zIndex: 1000,
          },
        }}
      >
        <div
          className="flex flex-col rounded gap-2 justify-top bg-white w-full h-full self-center p-10 drop-shadow-2xl text-left"
          style={{
            background: 'linear-gradient(to right, #000b1e, #0e1c3f)',
          }}
        >
          <div className="w-full justify-end flex text-white pb-4">
            <IconX onClick={() => setIsOpen(false)} className="w-[4rem] stroke-current" />
          </div>
          <div className="flex flex-col gap-4 overflow-scroll max-h-[50vh]">
            {!!!config ? (
              <div>Loading...</div>
            ) : (
              <div>
                <select
                  onChange={(e) => {
                    setDataset(e.target.value);
                    // set defaults for everything based on this dataset
                    setDataSpaceArguments((dataspaceArguments:any) => ({
                        ...dataspaceArguments,
                        xAxis: config.DATA_SETS[e.target.value].dimensions[0],
                        yAxis: config.DATA_SETS[e.target.value].dimensions[1],
                        idealXAxisCenter: 0.5,
                        idealYAxisCenter: 0.5,
                        dataPoints: []
                        }));
                  }}
                  value={dataset}
                >
                  {Object.keys(config.DATA_SETS).map((key) => {
                    return (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
            <div>
              {!!config && dataset ? (
                <div>
                  <select onChange={(e) => setRankingAlgorithm(e.target.value)} value={rankingAlgorithm}>
                    {config.DATA_SETS[dataset].ranking_methods.map((method: string) => {
                      return (
                        <option value={method} key={method}>
                          {method}
                        </option>
                      );
                    })}
                  </select>
                </div>
              ) : (
                <></>
              )}
            </div>
            {!!config && <div>
            <h2 className="text-lg text-white"> UI Fields</h2>
            <div>
                <label className="text-white">X Axis</label>
                <select onChange={(e) => setDataSpaceArguments((dataspaceArgs:any) => ({...dataspaceArgs, xAxis: e.target.value}))} value={dataSpaceArguments.xAxis}>
                    {config.DATA_SETS[dataset].dimensions.map((dimension: string) => {
                        return (
                            <option value={dimension} key={dimension}>
                                {dimension}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div>
                <label className="text-white">Y Axis</label>
                <select onChange={(e) => setDataSpaceArguments((dataspaceArgs:any) => ({...dataspaceArgs, yAxis: e.target.value}))} value={dataSpaceArguments.yAxis}>
                    {config.DATA_SETS[dataset].dimensions.map((dimension: string) => {
                        return (
                            <option value={dimension} key={dimension}>
                                {dimension}
                            </option>
                        );
                    })}
                </select>
            </div>
            <div>
                <label className="text-white">Ideal X Axis Center (0-10)</label>
                <input type="number" onChange={(e) => setDataSpaceArguments((dataspaceArgs:any) => ({...dataspaceArgs, idealXAxisCenter: (parseInt(e.target.value) || 0)/10}))} value={dataSpaceArguments.idealXAxisCenter || 0} />
            </div>
            <div>
                <label className="text-white">Ideal Y Axis Center (0-10)</label>
                <input type="number" onChange={(e) => setDataSpaceArguments((dataspaceArgs:any) => ({...dataspaceArgs, idealYAxisCenter: (parseInt(e.target.value) || 0)/10}))} value={dataSpaceArguments.idealYAxisCenter || 0} />
            </div>
            
          </div>
            
          }


            <div>
            <h2 className="text-lg text-white"> Retrieve New Data Fields</h2>
              <select onChange={(e) => setOperation(e.target.value)} value={operation}>
                {!!config &&
                  Object.keys(config.OPERATIONS).map((key) => {
                    return (
                      <option value={key} key={key}>
                        {key}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div>
              {!!config && operation && dataset ? (
                <div className="flex flex-col gap-2">
                  {config.OPERATIONS[operation].fields.map((field: string) => {
                    if (field === 'dimensions') {
                      return (
                        <div key={field} className="flex flex-col gap-2">
                          {/* Global Fields */}
                          <h2 className="text-white">Set All Dimensions</h2>
                          {(['min', 'max', 'elas_low', 'elas_high'] as const).map((field) => (
                            <div key={field} className="flex flex-row items-center gap-2">
                              <label className="text-white capitalize">{`Set All ${field.replace('_', ' ')}`}</label>
                              <input
                                type="number"
                                value={globalValues[field]}
                                onChange={(e) =>
                                  setGlobalValues((prev) => ({ ...prev, [field]: e.target.value }))
                                }
                                placeholder={`Set All ${field.replace('_', ' ')}`}
                                className="w-24 h-8"
                              />
                              <button
                                onClick={() =>
                                  dispatchDimensions({
                                    type: 'SET_ALL_DIMENSION_VALUES',
                                    payload: { field, value: globalValues[field] },
                                  })
                                }
                                className="bg-white rounded text-black px-2"
                              >
                                Set All
                              </button>
                            </div>
                          ))}

                          {/* Fields for Each Dimension */}
                          <div className="flex flex-col gap-8">
                            {Object.keys(dimensionsState).map((dimension) => (
                              <div key={dimension} className="flex flex-row gap-1 flex-wrap">
                                <h3 className="text-white w-full">{dimension}</h3>
                                {(['min', 'max', 'elas_low', 'elas_high'] as const).map((field) => (
                                  <div key={field} className="flex flex-row items-center gap-2">
                                    <label className="text-white capitalize">
                                      {field.replace('_', ' ')}
                                    </label>
                                    <input
                                      type="number"
                                      value={dimensionsState[dimension][field]}
                                      onChange={(e) =>
                                        dispatchDimensions({
                                          type: 'SET_DIMENSION_VALUE',
                                          payload: {
                                            dimension,
                                            field,
                                            value: e.target.value,
                                          },
                                        })
                                      }
                                      placeholder={`${dimension} ${field.replace('_', ' ')}`}
                                      className="w-24 h-8"
                                    />
                                  </div>
                                ))}
                              </div>
                            ))}
                          </div>
                        </div>
                      );
                    }
                    if (field === 'query') {
                      return (
                        <div key={field} className="flex flex-col gap-2">
                          <input
                            type="text"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            placeholder="Query"
                          />
                        </div>
                      );
                    }
                    return null;
                  })}
                </div>
              ) : (
                <></>
              )}
            </div>

            {!!config && dataset && operation ? (
              <button
                className="bg-white rounded text-black"
                onClick={() => {
                  console.log("Dataset", dataset);
                  console.log("Ranking Algorithm", rankingAlgorithm);
                  console.log("Query", query);
                  console.log("Operation", operation);
                  console.log(config.OPERATIONS[operation]);

                  interface DatasetOperationParams {
                    query?: string;
                    dimensions?: Record<string, any>;
                  }
                  let parameters: DatasetOperationParams = {};

                  if (config.OPERATIONS[operation].fields.includes('query')) {
                    parameters['query'] = query;
                  }

                  if (config.OPERATIONS[operation].fields.includes('dimensions')) {
                    parameters['dimensions'] = {};
                    for (const dimension in dimensionsState) {
                      const { min, max, elas_low, elas_high } = dimensionsState[dimension];
                      parameters['dimensions'][dimension] = {
                        min: parseFloat(min),
                        max: parseFloat(max),
                        elas_low: parseFloat(elas_low),
                        elas_high: parseFloat(elas_high),
                      };
                    }
                  }

                  console.log("Dimensions", parameters['dimensions'])
                  db.endpoints.dataProcessing
                    .dataProcessingCreate({
                      dataset,
                      operation,
                      ranking_algorithm: rankingAlgorithm,
                      ...parameters,
                    })
                    .then((response) => {
                      console.log("Operation result:", response.data);
                      console.log("DataSpace Arguments", dataSpaceArguments);
                      setDataSpaceArguments((dataspaceArgs:any) => ({...dataspaceArgs, ...response.data}));
                    })
                    .catch((error) => {
                      console.error("Error:", error);
                    });
                }}
              >
                Submit
              </button>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DatasetModal;
