import { cn } from "../../utils/cn";

export const Card = ({
  className,
  children,
  onClick
}: {
  className?: string;
  children: React.ReactNode;
  onClick?: () => void;
}) => {
  return (
    <div
      className={cn(
        "rounded-2xl h-full w-full shadow-input p-4 overflow-hidden bg-white border border-transparent group-hover:border-slate-700 relative",
        className
      )}
      onClick={onClick}
    >

      <div className="relative h-full w-full">
        <div className="p-4 h-[350px] w-full flex flex-col justify-between">{children}</div>
      </div>
    </div>
  );
};
export const CardTitle = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <h4 className={cn("text-black font-bold tracking-wide mt-4", className)}>
      {children}
    </h4>
  );
};

export const CardSubTitle = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <h4 className={cn("text-black text-xs font-bold tracking-wide", className)}>
      {children}
    </h4>
  );
};


export const CardDescription = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <p
      className={cn(
        "mt-8 text-black tracking-wide leading-relaxed text-sm",
        className
      )}
    >
      {children}
    </p>
  );
};
