/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AuthorizationCode {
  code: string;
}

export interface ChatGetNotFoundResponse {
  detail: string;
}

export interface ChatPostBadRequestResponse {
  detail: string;
}

export interface ChatPostRequest {
  conversation_id?: number;
  message: string;
}

export interface Conversation {
  id: number;
  /** @format date-time */
  created_at: string;
  account: number;
  published_to_web?: boolean;
  /** @maxLength 255 */
  title: string;
  messages: Message[];
}

export interface DataProcessingRequest {
  dataset: string;
  ranking_algorithm: string;
  operation: string;
  dimensions?: Record<string, any>;
  query?: string;
}

export interface DataProcessingResponse {
  computedAxis: string;
  dataPoints: Record<string, any>[];
  dimensions: string[];
  database: string;
}

export interface DataSetConfigurationResponse {
  DATA_SETS: Record<string, Record<string, any>>;
  OPERATIONS: Record<string, Record<string, any>>;
}

/**
 * * `single` - Single Deadline
 * * `multiple_cut_off` - Multiple Cut-Off
 * * `two_stage` - Two-Stage
 */
export enum DeadlineModelEnum {
  Single = "single",
  MultipleCutOff = "multiple_cut_off",
  TwoStage = "two_stage",
}

export interface EUFunctionDimensionRequest {
  params: Record<string, any>;
  query: string;
  wished_for_dimensions: Record<string, any>;
  dimensions_score: number;
  semantic_score: number;
  conversation_summary: string;
}

export interface EUFunctionDimensionResponse {
  response: Record<string, any>;
}

export interface EUFundingSource {
  id: number;
  /** @maxLength 255 */
  external_id: string;
  /** @maxLength 255 */
  title?: string | null;
  summary?: string | null;
  content?: string | null;
  /**
   * @format uri
   * @maxLength 1024
   */
  url: string;
  /** @maxLength 255 */
  identifier?: string | null;
  /** @maxLength 255 */
  topic_title?: string | null;
  /** @maxLength 255 */
  call_title?: string | null;
  description?: string | null;
  conditions?: string | null;
  /**
   * * `single` - Single Deadline
   * * `multiple_cut_off` - Multiple Cut-Off
   * * `two_stage` - Two-Stage
   */
  deadline_model: DeadlineModelEnum;
  /**
   * @format decimal
   * @pattern ^-?\d{0,12}(?:\.\d{0,2})?$
   */
  min_grant?: string | null;
  /**
   * @format decimal
   * @pattern ^-?\d{0,12}(?:\.\d{0,2})?$
   */
  max_grant?: string | null;
  /**
   * @min -2147483648
   * @max 2147483647
   */
  number_of_grants?: number | null;
  deadlines?: string[];
  extra_information?: any;
  geographies?: any;
  ai_summary?: string | null;
  /** @format date-time */
  earliest_deadline?: string | null;
  /** @format double */
  research_program_scale?: number | null;
  /** @format double */
  innovation_program_scale: number;
  /** @format double */
  education_program_scale: number;
  /** @format double */
  regional_development_program_scale: number;
  /** @format double */
  total_funding_amount_scale: number;
  /** @format double */
  relative_funding_scale: number;
  /** @format double */
  funding_size_comparison_scale: number;
  /** @format double */
  individual_eligibility: number;
  /** @format double */
  non_profit_organization_eligibility: number;
  /** @format double */
  educational_institution_eligibility: number;
  /** @format double */
  business_eligibility: number;
  /** @format double */
  eu_member_state_engagement: number;
  /** @format double */
  associated_nations_engagement: number;
  /** @format double */
  geographical_diversity: number;
  /** @format double */
  alignment_with_eu_policy: number;
  /** @format double */
  application_complexity: number;
  /** @format double */
  submission_method_variety: number;
  /** @format double */
  documentation_requirements: number;
  /** @format double */
  deadline_flexibility: number;
  /** @format double */
  short_term_project_duration: number;
  /** @format double */
  medium_term_project_duration: number;
  /** @format double */
  long_term_project_duration: number;
  /** @format double */
  sustainability_focus: number;
  /** @format double */
  innovation_emphasis: number;
  /** @format double */
  job_creation_intent: number;
  /** @format double */
  social_inclusion_goals: number;
  /** @format double */
  co_funding_requirement_level: number;
  /** @format double */
  impact_on_project_financing: number;
  /** @format double */
  partnership_necessity: number;
  /** @format double */
  flexibility_of_co_funding_sources: number;
  /** @format double */
  impact_on_project_partnerships: number;
  /** @format double */
  governance_complexity: number;
  /** @format double */
  authority_involvement: number;
  /** @format double */
  governance_model_clarity: number;
  /** @format double */
  efficiency_of_grant_administration: number;
  /** @format double */
  stakeholder_engagement: number;
  /** @format double */
  management_responsibility_distribution: number;
  /** @format double */
  shared_management_effectiveness: number;
  /** @format double */
  flexibility_of_framework: number;
  /** @format double */
  monitoring_clarity: number;
  /** @format double */
  reporting_frequency: number;
  /** @format double */
  accountability_standards: number;
  /** @format double */
  compliance_complexity: number;
  /** @format double */
  data_management_requirements: number;
  /** @format double */
  monitoring_obligation_comprehensiveness: number;
  /** @format double */
  clarity_of_reporting_requirements: number;
  /** @format double */
  accountability_effectiveness: number;
  /** @format double */
  reporting_accuracy: number;
  /** @format double */
  adherence_to_use_of_funds: number;
  /** @format double */
  feedback_mechanism_effectiveness: number;
}

export interface LLMFunctionCallerRequest {
  function_name: string;
  arguments: Record<string, any>;
}

export interface LLMFunctionCallerResponse {
  response: Record<string, any>;
}

export interface Login {
  email: string;
  password: string;
}

export interface Message {
  id: number;
  conversation_id: number;
  message: any;
  /** @format date-time */
  created_at: string;
}

export interface SignupError {
  error: Record<string, string[]>;
}

export interface StripeCheckout {
  /** @format uri */
  stripe_checkout_url: string;
}

export interface StripePortal {
  /** @format uri */
  stripe_portal_url: string;
}

export interface User {
  /** @maxLength 150 */
  first_name?: string;
  /** @maxLength 150 */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
}

export interface UserResponse {
  user: User;
}

export interface UserSignup {
  /** @maxLength 150 */
  first_name?: string;
  /** @maxLength 150 */
  last_name?: string;
  /**
   * Email address
   * @format email
   * @maxLength 254
   */
  email?: string;
  /** @maxLength 128 */
  password: string;
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from "axios";
import axios from "axios";

export type QueryParamsType = Record<string | number, any>;

export interface FullRequestParams extends Omit<AxiosRequestConfig, "data" | "params" | "url" | "responseType"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType;
  /** request body */
  body?: unknown;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, "data" | "cancelToken"> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void;
  secure?: boolean;
  format?: ResponseType;
}

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance;
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private secure?: boolean;
  private format?: ResponseType;

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || "" });
    this.secure = secure;
    this.format = format;
    this.securityWorker = securityWorker;
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method);

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === "object" && formItem !== null) {
      return JSON.stringify(formItem);
    } else {
      return `${formItem}`;
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input;
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key];
      const propertyContent: any[] = property instanceof Array ? property : [property];

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File;
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem));
      }

      return formData;
    }, new FormData());
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const responseFormat = format || this.format || undefined;

    if (type === ContentType.FormData && body && body !== null && typeof body === "object") {
      body = this.createFormData(body as Record<string, unknown>);
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== "string") {
      body = JSON.stringify(body);
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { "Content-Type": type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    });
  };
}

/**
 * @title Oasis API
 * @version 1.0.0
 *
 * Oasis - Search
 */
export class OasisBackendApi<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  authorize = {
    /**
     * No description
     *
     * @tags authorize
     * @name AuthorizeRetrieve
     * @request GET:/authorize/
     * @secure
     */
    authorizeRetrieve: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/authorize/`,
        method: "GET",
        secure: true,
        ...params,
      }),

    /**
     * No description
     *
     * @tags authorize
     * @name AuthorizeCreate
     * @request POST:/authorize/
     * @secure
     */
    authorizeCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/authorize/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  conversation = {
    /**
     * No description
     *
     * @tags conversation
     * @name ConversationRetrieve
     * @request GET:/conversation/
     * @secure
     */
    conversationRetrieve: (
      query: {
        conversation_id: number;
      },
      params: RequestParams = {},
    ) =>
      this.request<Conversation, ChatGetNotFoundResponse>({
        path: `/conversation/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags conversation
     * @name ConversationCreate
     * @request POST:/conversation/
     * @secure
     */
    conversationCreate: (data: ChatPostRequest, params: RequestParams = {}) =>
      this.request<Conversation, ChatPostBadRequestResponse>({
        path: `/conversation/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  csrfToken = {
    /**
     * No description
     *
     * @tags csrf_token
     * @name CsrfTokenCreate
     * @request POST:/csrf_token/
     * @secure
     */
    csrfTokenCreate: (params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/csrf_token/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  dataProcessing = {
    /**
     * No description
     *
     * @tags data-processing
     * @name DataProcessingCreate
     * @request POST:/data-processing/
     * @secure
     */
    dataProcessingCreate: (data: DataProcessingRequest, params: RequestParams = {}) =>
      this.request<DataProcessingResponse, any>({
        path: `/data-processing/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  dataSetConfiguration = {
    /**
     * @description This view returns the DATA_SET_CONFIGURATION for frontend configuration.
     *
     * @tags data-set-configuration
     * @name DataSetConfigurationRetrieve
     * @request GET:/data-set-configuration/
     * @secure
     */
    dataSetConfigurationRetrieve: (params: RequestParams = {}) =>
      this.request<DataSetConfigurationResponse, any>({
        path: `/data-set-configuration/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  euFundingSource = {
    /**
     * No description
     *
     * @tags eu-funding-source
     * @name EuFundingSourceRetrieve
     * @request GET:/eu-funding-source/{id}/
     * @secure
     */
    euFundingSourceRetrieve: (id: number, params: RequestParams = {}) =>
      this.request<EUFundingSource, any>({
        path: `/eu-funding-source/${id}/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  euFundingHybridParallelSearch = {
    /**
     * No description
     *
     * @tags eu_funding_hybrid_parallel_search
     * @name EuFundingHybridParallelSearchCreate
     * @request POST:/eu_funding_hybrid_parallel_search/
     * @secure
     */
    euFundingHybridParallelSearchCreate: (data: EUFunctionDimensionRequest, params: RequestParams = {}) =>
      this.request<EUFunctionDimensionResponse, any>({
        path: `/eu_funding_hybrid_parallel_search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  euFundingOasisSearch = {
    /**
     * No description
     *
     * @tags eu_funding_oasis_search
     * @name EuFundingOasisSearchCreate
     * @request POST:/eu_funding_oasis_search/
     * @secure
     */
    euFundingOasisSearchCreate: (data: EUFunctionDimensionRequest, params: RequestParams = {}) =>
      this.request<EUFunctionDimensionResponse, any>({
        path: `/eu_funding_oasis_search/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  euFundingTraditionalRag = {
    /**
     * No description
     *
     * @tags eu_funding_traditional_rag
     * @name EuFundingTraditionalRagRetrieve
     * @request GET:/eu_funding_traditional_rag/
     * @secure
     */
    euFundingTraditionalRagRetrieve: (params: RequestParams = {}) =>
      this.request<EUFundingSource, any>({
        path: `/eu_funding_traditional_rag/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  generateAuthCode = {
    /**
     * No description
     *
     * @tags generate_auth_code
     * @name GenerateAuthCodeCreate
     * @request POST:/generate_auth_code/
     * @secure
     */
    generateAuthCodeCreate: (params: RequestParams = {}) =>
      this.request<AuthorizationCode, any>({
        path: `/generate_auth_code/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  llmFunctionCaller = {
    /**
     * No description
     *
     * @tags llm_function_caller
     * @name LlmFunctionCallerCreate
     * @request POST:/llm_function_caller/
     * @secure
     */
    llmFunctionCallerCreate: (data: LLMFunctionCallerRequest, params: RequestParams = {}) =>
      this.request<LLMFunctionCallerResponse, any>({
        path: `/llm_function_caller/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  login = {
    /**
     * No description
     *
     * @tags login
     * @name LoginCreate
     * @request POST:/login/
     * @secure
     */
    loginCreate: (data: Login, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/login/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  logout = {
    /**
     * No description
     *
     * @tags logout
     * @name LogoutCreate
     * @request POST:/logout/
     * @secure
     */
    logoutCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/logout/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
  signup = {
    /**
     * No description
     *
     * @tags signup
     * @name SignupCreate
     * @request POST:/signup/
     * @secure
     */
    signupCreate: (data: UserSignup, params: RequestParams = {}) =>
      this.request<void, SignupError>({
        path: `/signup/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  stripeEuCallCheckout = {
    /**
     * No description
     *
     * @tags stripe_eu_call_checkout
     * @name StripeEuCallCheckoutCreate
     * @request POST:/stripe_eu_call_checkout/
     * @secure
     */
    stripeEuCallCheckoutCreate: (params: RequestParams = {}) =>
      this.request<StripeCheckout, any>({
        path: `/stripe_eu_call_checkout/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  stripePortal = {
    /**
     * No description
     *
     * @tags stripe_portal
     * @name StripePortalCreate
     * @request POST:/stripe_portal/
     * @secure
     */
    stripePortalCreate: (params: RequestParams = {}) =>
      this.request<StripePortal, any>({
        path: `/stripe_portal/`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  user = {
    /**
     * No description
     *
     * @tags user
     * @name UserRetrieve
     * @request GET:/user
     * @secure
     */
    userRetrieve: (params: RequestParams = {}) =>
      this.request<UserResponse, any>({
        path: `/user`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  verifyToken = {
    /**
     * No description
     *
     * @tags verify_token
     * @name VerifyTokenCreate
     * @request POST:/verify_token/
     * @secure
     */
    verifyTokenCreate: (params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/verify_token/`,
        method: "POST",
        secure: true,
        ...params,
      }),
  };
}
