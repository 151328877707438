"use client";
import React, { useState, useCallback, useMemo } from "react";
import { motion } from "framer-motion";
import DataCard from "./DataCard";
import styled from "@emotion/styled";

const MotionContainer = styled(motion.div)`
  background: linear-gradient(290deg, #482852 0%, #04132C 80%);
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.2);
`;

type DataCardType = {
  yAxis: string;
  xAxis: string;
  data: any;
  database: string;
  dimensions: string[];
  key: string;
};

interface DataCardStackProps {
  items: DataCardType[];
  offset?: number;
  scaleFactor?: number;
}

export const DataCardStack = React.memo(({ items, offset, scaleFactor }: DataCardStackProps) => {
  const CARD_OFFSET = offset || 10;
  const SCALE_FACTOR = scaleFactor || 0.06;
  const MAX_CARDS_DISPLAYED = 5;

  // Initialize cards state with items
  const [cards, setCards] = useState<DataCardType[]>(items);

  // Memoize the displayed cards to prevent unnecessary recalculations
  const cardsToDisplay = useMemo(() => {
    return cards.slice(0, Math.min(MAX_CARDS_DISPLAYED, cards.length));
  }, [cards]);

  // Memoize the nextCard function to prevent re-creation on every render
  const nextCard = useCallback(() => {
    setCards((prevCards) => {
      if (prevCards.length <= 1) return prevCards;
      const newArray = [...prevCards];
      newArray.unshift(newArray.pop()!); // Rotate the cards
      return newArray;
    });
  }, []);

  return (
    <div className="relative h-full w-full">
      {cardsToDisplay.map((card, index) => (
        <MotionContainer
          key={card.key}
          className="absolute h-full w-full rounded-3xl p-4 shadow-xl border shadow-white/[0.1] flex flex-col justify-between"
          style={{
            transformOrigin: "top center",
          }}
          animate={{
            top: index * -CARD_OFFSET,
            scale: 1 - index * SCALE_FACTOR, // Decrease scale for cards that are behind
            zIndex: MAX_CARDS_DISPLAYED - index, // Adjust z-index for displayed cards
          }}
        >
          <DataCard
            data={card.data}
            database={card.database}
            dimensions={card.dimensions}
            xAxis={card.xAxis}
            yAxis={card.yAxis}
            nextCard={nextCard}
          />
        </MotionContainer>
      ))}
    </div>
  );
});
